.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1140px;
}

.link-danger{
  text-decoration: none;
}

.img-mymdb {
  max-width: 65%;
}

.card-text {
  margin-bottom: 7px;
}

.card-text:last-child {
  margin-bottom: 7px;
}

.desc-bloc {
  border: 0;
}

.result-info-success {
  background-color: #025464;
  color:white;
}

.result-info-fail {
  background-color: grey;
  color:white;
}

.list-group-item-p {
  padding: 0.25rem 1.25rem;
}

.list-group-item-p a{
  text-decoration: none;
}

.img-login {
  padding-top: 50px;
  padding-bottom: 10px;
}

.login-title {
  color: #fff;
  padding: 10px 10px;
}

.source-img {
  max-width: 50px;
  border-radius: 50%;
}

.mymdb-nav {
  background-color: black;
}

.lib-search {
  width: auto;
  min-width: 16%;
}

.login-background {
  background-image: url(/img/background.jpg);
  background-size: cover;
  background-color: black;
  background-repeat: no-repeat;
  height: 100%;
  font-family: "Numans", sans-serif;
}

.login-row {
  padding-top: 30%;
}

.login-col-md-6 {
  background-color: rgba(0, 0, 0, 0.5) !important;
  border-radius: 20px;
}

* {
  padding: 0;
  margin: 0;
}

/***************************************/
/*      DEBUT LIST CARD                */
/***************************************/

.abs,
.cards .card-item figcaption,
.cards .card-item:after,
.news .card-item figcaption,
.news .card-item:after,
.news .article figcaption {
  position: absolute;
}
.rel,
.cards .card-item,
.news .card-item,
.news .article {
  position: relative;
}
.fix {
  position: fixed;
}
.dfix {
  display: inline;
}
.dib {
  display: inline-block;
}
.db {
  display: block;
}
.dn {
  display: none;
}
.df,
.cards,
.news {
  display: flex;
}
.dif {
  display: inline-flex;
}
.dg {
  display: grid;
}
.dig {
  display: inline-grid;
}
.vm {
  vertical-align: middle;
}

.wrapper {
  padding: 15px;
}

.cards {
  flex-flow: row wrap;
}
.cards .card-item {
  margin: 20px;
  width: 175px;
  height: 270px;
  overflow: hidden;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.8);
  transform-origin: center top;
  transform-style: preserve-3d;
  transform: translateZ(0);
  transition: 0.3s;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .cards .card-item {
    margin: 10px;
    width: 155px;
    height: 230px;
    overflow: hidden;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.8);
    transform-origin: center top;
    transform-style: preserve-3d;
    transform: translateZ(0);
    transition: 0.3s;
  }
}

.cards .card-item img {
  width: 100%;
  min-height: 100%;
}
.cards .card-item figcaption {
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  padding-bottom: 10px;
  font-size: 12px;
  background: none;
  color: #fff;
  transform: translateY(100%);
  transition: 0.3s;
}
.cards .card-item:after {
  content: "";
  z-index: 10;
  width: 200%;
  height: 100%;
  top: -90%;
  left: -20px;
  opacity: 0.1;
  transform: rotate(45deg);
  background: linear-gradient(
    to top,
    transparent,
    #fff 15%,
    rgba(255, 255, 255, 0.5)
  );
  transition: 0.3s;
}
.cards .card-item:hover,
.cards .card-item:focus,
.cards .card-item:active {
  box-shadow: 0 8px 16px 3px rgba(0, 0, 0, 0.6);
  transform: translateY(-3px) scale(1.05) rotateX(15deg);
}
.cards .card-item:hover figcaption,
.cards .card-item:focus figcaption,
.cards .card-item:active figcaption {
  transform: none;
}
.cards .card-item:hover:after,
.cards .card-item:focus:after,
.cards .card-item:active:after {
  transform: rotate(25deg);
  top: -40%;
  opacity: 0.15;
}
.news .article {
  overflow: hidden;
  width: 350px;
  height: 235px;
  margin: 20px;
}

.hr-title {
  width: 100%;
  color: black;
  height: 1px;
  background-color: black;
}

.back-to-top {
  position: fixed;
  bottom: 25px;
  right: 25px;
  display: none;
}

.fas-style {
  color: white;
}


.c100.small {
  font-size: 50px;
}

.c100 > span {
  color:black;
}

.c100 .bar{
  border: 0.08em solid #1D267D
}


/**** ACCORDIAN ****/

.gallery-wrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 220px;
}

.item {
  flex: 1;
  height: 220px;
  background-position: center;
  background-size: cover;
  background-repeat: none;
  transition: flex 0.7s;
}

.item:hover{
  flex: 300px;
}

/* The side navigation menu */
.offcanvas{
  width: auto;
  background-color: #30363d;
  color: white;
}

.navbar, .back-to-top, .lib-search{
  background-color: #30363d;
  color: white;
}

.login-input{
  width: 100%;
}

.btn-login{
  margin: 5px;
}
